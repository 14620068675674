.fade-enter {
    transform: translateX(100%) scale(0.7);
    
    opacity: 0;
  }
  .fade-enter-active {
    opacity: 1;
    transform: translateX(0%) scale(1);
    
    transition: all 300ms ease-in;
  }
  .fade-exit {
    opacity: 1;
  }
  .fade-exit-active {
    animation-duration: 0.5s;
    animation-name: fade-out;
  
    transition: opacity 500ms ease-in;
  }
  
  @keyframes fade-out{
    30%{
      transform: scale(1.2);
      
    }
  
    100%{
      opacity: 0;
      transform: scale(0.1);
    }
  }
  @keyframes slide{
    0%{
      top: -30%;
      opacity: 0;
      
    }
  
    100%{
      top: 0;
      /* top: 0; */
      opacity: 1;
      
    }
  }