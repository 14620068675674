.thumb-vertical{
    background-color: white;
    width: 8px !important;
    border-radius: 10px;
    display: flex !important;
    justify-content: center;
    align-items: center;
    opacity: 0.7;
}

.thumb-vertical::after{
    content: '';
    display: block;
    height: 1500%;
    width: 1px;
    background-color: #FFFFFF;
    position: absolute;
    transform: translateY(0%);
}

.thumb-vertical:hover {
    cursor: move; /* fallback if grab cursor is unsupported */
    cursor: grab;
    cursor: -moz-grab;
    cursor: -webkit-grab;
    opacity: 1;
}

.thumb-vertical:active {
    cursor: grabbing;
    cursor: -moz-grabbing;
    cursor: -webkit-grabbing;
}

.track-vertical {
    right: 2px;
    bottom: 2px;
    top: 2px;
    border-radius: 3px;
    width: 9px !important;
    height: 100%;
}