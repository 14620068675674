/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on May 8, 2018 */

@font-face {
    font-family: 'Proxima Nova';
    src: url('proxima-nova-black-webfont.woff2') format('woff2'),
         url('proxima-nova-black-webfont.woff') format('woff'),
         url('proxima-nova-black-webfont.ttf') format('truetype'),
         url('proxima-nova-black-webfont.svg#proxima_nova_blblack') format('svg');
    font-weight: 800;
    font-style: normal;

}
@font-face {
    font-family: 'Proxima Nova';
    src: url('proxima-nova-bold-webfont.woff2') format('woff2'),
         url('proxima-nova-bold-webfont.woff') format('woff'),
         url('proxima-nova-bold-webfont.ttf') format('truetype'),
         url('proxima-nova-bold-webfont.svg#proxima_novabold') format('svg');
    font-weight: 600;
    font-style: normal;

}

@font-face {
    font-family: 'Proxima Nova';
    src: url('proxima-nova-extrabold-webfont.woff2') format('woff2'),
         url('proxima-nova-extrabold-webfont.woff') format('woff'),
         url('proxima-nova-extrabold-webfont.ttf') format('truetype'),
         url('proxima-nova-extrabold-webfont.svg#proxima_nova_thextrabold') format('svg');
    font-weight: 700;
    font-style: normal;

}

@font-face {
    font-family: 'Proxima Nova';
    src: url('proxima-nova-thin-webfont.woff2') format('woff2'),
         url('proxima-nova-thin-webfont.woff') format('woff'),
         url('proxima-nova-thin-webfont.ttf') format('truetype'),
         url('proxima-nova-thin-webfont.svg#proxima_novathin') format('svg');
    font-weight: 100;
    font-style: normal;

}

@font-face {
    font-family: 'Proxima Nova';
    src: url('proxima-nova-light-webfont.woff2') format('woff2'),
         url('proxima-nova-light-webfont.woff') format('woff'),
         url('proxima-nova-light-webfont.ttf') format('truetype'),
         url('proxima-nova-light-webfont.svg#proxima_nova_ltlight') format('svg');
    font-weight: 200;
    font-style: normal;

}

@font-face {
    font-family: 'Proxima Nova';
    src: url('proxima-nova-webfont.woff2') format('woff2'),
         url('proxima-nova-webfont.woff') format('woff'),
         url('proxima-nova-webfont.ttf') format('truetype'),
         url('proxima-nova-webfont.svg#proxima_nova_rgregular') format('svg');
    font-weight: 400;
    font-style: normal;

}

@font-face {
    font-family: 'Proxima Nova';
    src: url('proxima-nova-semibold-webfont.woff2') format('woff2'),
         url('proxima-nova-semibold-webfont.woff') format('woff'),
         url('proxima-nova-semibold-webfont.ttf') format('truetype'),
         url('proxima-nova-semibold-webfont.svg#proxima_nova_ltsemibold') format('svg');
    font-weight: 500;
    font-style: normal;

}



