body {
  margin: 0;
  padding: 0;
  background-color: #05334F;
  color: #FFFFFF;
  font-family: "Proxima Nova", Arial, sans-serif;
  font-weight: 200;

  overflow: hidden auto;
}

input, textarea, select, button {
  font-family: "Proxima Nova", Arial, sans-serif;
}

body, html, body *, html *, body:before, body:after, html:before, html:after, body *:before, body *:after, html *:before, html *:after {
	box-sizing: border-box;
} 

a {
  color: inherit;
}

#root{
  min-height: 100vh;
  height: 100vh;
  /* max-height: 100vh; */
  display: flex;
  align-items: center;
  overflow-x: auto;
  width: 100vw;
}

#container{
  position: relative;
  width: 480px;
  height: 320px;
  overflow: auto;
}

#container .content {
  width: 1280px;
  height: 720px;
}

.blas-enter {
  opacity: 0;
  z-index: 1;
}

.blas-enter.blas-enter-active {
  opacity: 1;
  transition: opacity 150ms ease-in;
}

.blas-exit-active{
    opacity: 0;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media screen and (max-width: 1024px){

}

@media screen and (max-width: 768px){
  #root{
    overflow: auto;
    overflow-x: hidden ;
  }
}

input[type=text]::-ms-clear,
input[type=number]::-ms-clear {
  display: none;
}

input[type=number] {
  -moz-appearance: textfield;
}
input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type=text],
input[type=number] {
  /*border-bottom: 1px solid rgba(255, 255, 255, 0.2);*/
  box-shadow: 0 -1px 0 0 rgba(255, 255, 255, 0.1) inset;
  transition: box-shadow 200ms ease-out;
}

input[type=text]:active,
input[type=number]:active,
input[type=text]:focus,
input[type=number]:focus {
  /*border-bottom: 1px solid rgba(255, 255, 255, 0.2);*/
  box-shadow: 0 -1px 0 0 rgba(146, 202, 177, 1) inset;
}

input[type=text]::placeholder,
input[type=number]::placeholder {
  font-weight: 200;
  color: rgba(255,255,255,0.4);
  font-size: 0.7vw;
  height: 2.25vw; /* for IE11 font-size x height (0.9vw x 2.5em) */
}

@media screen and (max-width: 1024px){
  input[type=text]::placeholder,
  input[type=number]::placeholder {
    font-size: 1.3vw;
    height: 3.75vw; /* for IE11 font-size x height (1.9vw x 2.5em) */
  }
}

@media screen and (max-width: 768px){
  input[type=text]::placeholder,
  input[type=number]::placeholder {
    font-size: 4.5vw;
    height: 11.25vw; /* for IE11 font-size x height (4.5vw x 2.5em) */
  }
}

.next-pressed input[type=text].validation-error,
.next-pressed input[type=number].validation-error {
  box-shadow: 0 -1px 0 0 #ff5656 inset;
}

.next-pressed input[type=text].validation-error::placeholder,
.next-pressed input[type=number].validation-error::placeholder {
  color: #ff6565;
}
/*------------*/
.range-input-wrapper {
  width: 55%;
  margin: 0 auto;
  height: 6em;
}

@media screen and (max-width: 1024px) {
  .range-input-wrapper {
    height: 2em;
  }
}

.next-pressed .validation-error input::placeholder,
.form-invalid .validation-error input::placeholder {
  color: #ff6565;
}

.next-pressed .validation-error,
.form-invalid .validation-error {
  color: #ff6565;
}

.next-pressed .validation-error .radio-with-text-radio,
.form-invalid .validation-error .radio-with-text-radio {
    border-color: #ff6565;
}